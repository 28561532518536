import 'antd/dist/antd.css';
import './App.css';
import {Button} from "antd";

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <img src={"/images/logo.svg"} alt={"logo"}/>
          <h1>We moved to a new home!</h1>
          <Button
              type="primary"
              size="large"
              href="https://app.1mrobotics.com">Take me there</Button>
      </header>
    </div>
  );
}

export default App;
